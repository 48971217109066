import { CommonModule } from '@angular/common';
// import { CurrentLocationsComponent } from '@ev-portals/dp/frontend/shared/feature';
import { HttpErrorResponse } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  inject,
  Input,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AngularModule } from '@atoms/angular';
import { CartFacade } from '@ev-portals/dp/frontend/purchase/data-access';
import { CartResponseDto } from '@ev-portals/dp/frontend/shared/api-client';
import { CartProxyService } from '@ev-portals/dp/frontend/shared/data-access';
import { FeedbackMessageService, NavigationService } from '@ev-portals/dp/frontend/shared/util';
import { LoadingOverlayComponent } from '@ev-portals/ev/frontend/ui-library';
import { BehaviorSubject } from 'rxjs';

import { LineItemComponent } from '../line-item';
import { LineItemTableComponent } from '../line-item-table';
import { OrderInfoComponent } from '../order-info';
import { PurchaseService } from '../purchase.service';

@Component({
  selector: 'dp-review',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    AngularModule,
    LineItemComponent,
    OrderInfoComponent,
    LoadingOverlayComponent,
    RouterModule,
    LineItemTableComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReviewComponent implements AfterViewInit {
  purchaseService = inject(PurchaseService);
  #feedbackMessageService = inject(FeedbackMessageService);
  #cartFacade = inject(CartFacade);
  #navigationService = inject(NavigationService);
  #cartProxyService = inject(CartProxyService);
  // TODO: add later
  // private analyticsService = inject(AnalyticsService);

  @Input() cart: CartResponseDto;

  purchaseFormGroup = this.purchaseService.purchaseFormGroup;
  cartFormGroup = this.purchaseService.cartFormGroup;
  orderInfoFormGroup = this.purchaseService.orderInfoFormGroup;
  reviewFormGroup = this.purchaseService.reviewFormGroup;

  gettingPaymentLinkIsLoading$ = new BehaviorSubject(false);
  verifyingPaymentIsLoading$ = new BehaviorSubject(false);

  agreeToTermLabel = 'I have read and agree with the';
  placingOrderText = 'Proceeding to payment...';

  constructor() {
    this.#cartProxyService.requestError$.pipe(takeUntilDestroyed()).subscribe(errorMessage => {
      this.#feedbackMessageService.showErrorMessage(errorMessage);
    });
  }

  ngAfterViewInit(): void {
    this.#navigationService.scrollToTop();
  }

  onNavigateToTerms = this.#navigationService.navigateToTermsAndConditions.bind(
    this.#navigationService,
  );

  onProceedToPayment(): void {
    this.gettingPaymentLinkIsLoading$.next(true);
    this.#cartFacade.getPaymentLink().subscribe({
      next: response => {
        if ('paymentLink' in response) {
          window.location.href = response.paymentLink;
        }
      },
      error: (error: HttpErrorResponse) => {
        this.gettingPaymentLinkIsLoading$.next(false);

        // error message for user
        const errorMessage = 'Order could not be validated. Please refresh and try again later.';
        this.#cartProxyService.setRequestError(errorMessage);

        // debug log in the console
        console.error(`[CartFacade] Error during cart update: ${error.message}`);
      },
    });
  }
}
