import { inject, Injectable, signal } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { CartFacade } from '@ev-portals/dp/frontend/purchase/data-access';
import {
  CartLineItemDto,
  CartResponseDto,
  WarehouseLocation,
} from '@ev-portals/dp/frontend/shared/api-client';
import {
  checkedValidator,
  dateFormatValidator,
  futureDateValidator,
  quantityValidators,
} from '@ev-portals/ev/frontend/util';
import { debounceTime } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PurchaseService {
  #cartFacade = inject(CartFacade);

  orderInfoFormGroup = this.#getEmptyOrderInfoFormGroup();
  cartFormGroup = this.#getEmptyCartFormGroup();
  reviewFormGroup = this.#getEmptyReviewFormGroup();
  // Combining the form groups of individual pages into a global one
  purchaseFormGroup: FormGroup<PurchaseForm> = new FormGroup({
    cart: this.cartFormGroup,
    orderInfo: this.orderInfoFormGroup,
    review: this.reviewFormGroup,
  });

  // We use debounce time to wait for more characters in the input fields before sending a request to the backend
  #DEBOUNCE_TIME = 500; // in ms
  Step = Step;

  $currentStep = signal<Step>(Step.Cart);

  constructor() {
    this.#initFormDefaultValues();
    this.#savePoNumberOnChange();
    this.#saveDeliveryInstructionsOnChange();
    this.#saveSelectedWareHouseLocationOnChange();
  }

  proceed() {
    this.$currentStep.update(v => v + 1);
  }

  back() {
    this.$currentStep.update(v => v - 1);
  }

  #savePoNumberOnChange(): void {
    // Purchase Order Number
    this.cartFormGroup.controls.poNumber.valueChanges
      .pipe(debounceTime(this.#DEBOUNCE_TIME))
      .subscribe(poNumber => {
        this.#cartFacade.changePurchaseOrderNumber(poNumber ?? '');
      });
  }

  #saveDeliveryInstructionsOnChange(): void {
    // Delivery Instructions
    this.cartFormGroup.controls.deliveryInstructions.valueChanges
      .pipe(debounceTime(this.#DEBOUNCE_TIME))
      .subscribe(deliveryInstructions => {
        this.#cartFacade.changeDeliveryInstructions(deliveryInstructions ?? '');
      });
  }

  #saveSelectedWareHouseLocationOnChange(): void {
    this.cartFormGroup.controls.warehouseLocation.valueChanges
      .pipe(debounceTime(this.#DEBOUNCE_TIME))
      .subscribe(warehouseLocation => {
        this.#cartFacade.changeWareHouseLocation(warehouseLocation);
      });
  }

  #initFormDefaultValues(): void {
    this.#cartFacade.cart$.pipe().subscribe(newCart => {
      // Purchase order number
      this.cartFormGroup.patchValue(
        {
          poNumber: newCart.poNumber,
          deliveryInstructions: newCart.deliveryInstructions,
          warehouseLocation: newCart.warehouseLocation,
        },
        { emitEvent: false },
      );

      this.#initDataForOrderInfo(newCart);

      // Sync LineItems
      this.#syncLineItemsWithForm(newCart.lineItems);
    });
  }

  #initDataForOrderInfo(cart: CartResponseDto): void {
    // Order Info page: get initial data from the cart
    // User Info
    this.orderInfoFormGroup.controls.userInfo.patchValue(cart.userInfo, {
      emitEvent: false,
    });

    // Shipping Address
    this.orderInfoFormGroup.controls.shippingAddress.patchValue(cart.shippingAddress ?? {}, {
      emitEvent: false,
    });
    // Billing Address
    this.orderInfoFormGroup.controls.billingAddress.patchValue(cart.billingAddress ?? {}, {
      emitEvent: false,
    });

    // terms and conditions
    this.reviewFormGroup.controls.agreedToTerms.patchValue(
      { checked: cart.termsAndConditionsAccepted },
      { emitEvent: false },
    );
  }

  #syncLineItemsWithForm(lineItems: CartLineItemDto[]): void {
    const lineItemControls = this.cartFormGroup.controls.lineItems.controls;
    // Remove non-existing elements
    for (let i = 0; i < lineItemControls.length; i++) {
      const existingLineItem = lineItems.find(
        lineItem => lineItemControls[i].value.id === lineItem.id,
      );

      if (!existingLineItem) {
        this.cartFormGroup.controls.lineItems.removeAt(i--);
      }
    }

    // Add new elements
    lineItems.forEach(lineItem => {
      const matchingLineItem = this.cartFormGroup.value.lineItems?.find(
        formLineItem => formLineItem.id === lineItem.id,
      );
      if (!matchingLineItem) {
        const cartFormGroup = this.#getCartItemFormGroup(lineItem);
        this.#addCartItemControlListeners(cartFormGroup);

        this.cartFormGroup.controls.lineItems.push(cartFormGroup);
      }
    });
  }

  #addCartItemControlListeners(cartFormGroup: FormGroup<CartItemForm>): void {
    cartFormGroup.controls.quantity.valueChanges
      .pipe(debounceTime(this.#DEBOUNCE_TIME))
      .subscribe(quantity => {
        if (cartFormGroup.controls.quantity.valid && cartFormGroup.value.id && quantity) {
          if (typeof quantity === 'string') {
            quantity = parseInt(quantity, 10);
          }
          this.#cartFacade.changeQuantity(cartFormGroup.value.id, quantity);
        }
      });

    cartFormGroup.controls.deliveryDate.valueChanges
      .pipe(debounceTime(this.#DEBOUNCE_TIME))
      .subscribe(deliveryDate => {
        if (cartFormGroup.controls.deliveryDate.valid && cartFormGroup.value.id && deliveryDate) {
          this.#cartFacade.changeDeliveryDate(cartFormGroup.value.id, deliveryDate);
        }
      });
  }

  #getEmptyOrderInfoFormGroup(): FormGroup<OrderInfoForm> {
    const userInfoFormGroup = new FormGroup<UserInfoForm>({
      name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      phoneNumber: new FormControl(null, [
        Validators.required,
        // we allow spaces in between and aroudn the string but will be removed before posting.
        Validators.pattern(/^\s*(?!0)\d(?:\s?\d){9}\s*$|^\s*\+\d(?:\s?\d){10,}\s*$/),
      ]),
      gstNumber: new FormControl(null, [Validators.required]),
      panNumber: new FormControl(null, [Validators.required]),
    });

    const shippingAddressFormGroup = new FormGroup<ShippingAddressForm>({
      zipCode: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      street: new FormControl('', [Validators.required]),
      additionalInfo: new FormControl(''),
    });

    const billingAddressFormGroup = new FormGroup<BillingAddressForm>({
      zipCode: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      street: new FormControl('', [Validators.required]),
      additionalInfo: new FormControl(''),
    });

    return new FormGroup<OrderInfoForm>({
      userInfo: userInfoFormGroup,
      shippingAddress: shippingAddressFormGroup,
      billingAddress: billingAddressFormGroup,
    });
  }

  #getEmptyCartFormGroup(): FormGroup<CartForm> {
    return new FormGroup<CartForm>({
      deliveryInstructions: new FormControl(''),
      poNumber: new FormControl('', [Validators.required]),
      warehouseLocation: new FormControl<WarehouseLocation | null>(null, [Validators.required]),
      lineItems: new FormArray<FormGroup<CartItemForm>>([]),
    });
  }

  #getEmptyReviewFormGroup(): FormGroup<ReviewForm> {
    return new FormGroup<ReviewForm>({
      agreedToTerms: new FormControl({ checked: false }, [checkedValidator()]),
    });
  }

  #getCartItemFormGroup(lineItem: CartLineItemDto): FormGroup<CartItemForm> {
    return new FormGroup<CartItemForm>({
      quantity: new FormControl(lineItem.quantity, quantityValidators),
      deliveryDate: new FormControl(lineItem.requestedDeliveryDate, [
        Validators.required,
        dateFormatValidator,
        futureDateValidator(),
      ]),
      id: new FormControl(lineItem.id),
    });
  }
}

export interface PurchaseForm {
  cart: FormGroup<CartForm>;
  orderInfo: FormGroup<OrderInfoForm>;
  review: FormGroup<ReviewForm>;
}

export interface UserInfoForm {
  name: FormControl<string | null>;
  email: FormControl<string | null>;
  phoneNumber: FormControl<string | null>;
  gstNumber: FormControl<string | null>;
  panNumber: FormControl<string | null>;
}

export interface ShippingAddressForm {
  zipCode: FormControl<string | null>;
  city: FormControl<string | null>;
  street: FormControl<string | null>;
  additionalInfo: FormControl<string | null>;
}

export interface BillingAddressForm {
  zipCode: FormControl<string | null>;
  city: FormControl<string | null>;
  street: FormControl<string | null>;
  additionalInfo: FormControl<string | null>;
}

export interface OrderInfoForm {
  userInfo: FormGroup<UserInfoForm>;
  shippingAddress: FormGroup<ShippingAddressForm>;
  billingAddress: FormGroup<BillingAddressForm>;
}

export interface CartForm {
  poNumber: FormControl<string | null>;
  warehouseLocation: FormControl<WarehouseLocation | null>;
  deliveryInstructions: FormControl<string | null>;
  lineItems: FormArray<FormGroup<CartItemForm>>;
}

export interface ReviewForm {
  // Note: this weird structure is forced by @atoms
  agreedToTerms: FormControl<{ checked: boolean } | null>;
}

export interface CartItemForm {
  quantity: FormControl<number | null>;
  deliveryDate: FormControl<string | null>;
  id: FormControl<string | null>;
}

export enum Step {
  Cart = 0,
  OrderInfo = 1,
  Review = 2,
}
