<form [formGroup]="purchaseFormGroup">
  <div class="table-title mb-4 flex justify-between">
    <ng-container>My Products</ng-container>
    @if (cart.lineItems.length !== 0) {
      <atom-button tertiary (click)="onClearCart()">
        <span>Clear Cart</span>
      </atom-button>
    }
  </div>

  <div
    class="additional-info-box grid grid-cols-2 max-lg:grid-cols-1 gap-4 mb-6 px-8 py-4 max-sm:p-2"
    formGroupName="cart"
  >
    <div class="pon-container">
      <div class="ev-input-label font-bold"
        ><span>Purchase order number</span>*
      </div>
      <input
        type="text"
        class="pon-input ev-input w-full"
        [ngClass]="{
          invalid: cartFormGroup.controls.poNumber.invalid,
        }"
        formControlName="poNumber"
        [placeholder]="poNumPlaceholder"
      />
      @if (cartFormGroup.controls.poNumber.invalid) {
        <div class="ev-input-error">
          Please enter a valid purchase order number.
        </div>
      }
    </div>

    <atoms-dropdown
      placeholder="Select a warehouse"
      size="small"
      label="Send from"
      atomsControl2
      formControlName="warehouseLocation"
    >
      @for (option of wareHouseOptions; track option) {
        <atoms-option value="{{ option }}">{{ option }}</atoms-option>
      }
    </atoms-dropdown>

    <div class="delivery-instructions-container">
      <div class="input-wrapper">
        <div class="ev-input-label font-bold">Delivery Instructions</div>
        <input
          type="text"
          class="ev-input w-full"
          [ngClass]="{
            invalid: cartFormGroup.controls.deliveryInstructions.invalid,
          }"
          formControlName="deliveryInstructions"
          [placeholder]="delInstructionPlaceholder"
        />
      </div>
    </div>
  </div>

  <dp-line-item-table [cart]="cart"></dp-line-item-table>

  <div
    class="actions-buttons mt-8 flex flex-wrap gap-4 justify-between max-sm:flex-col-reverse"
  >
    <atom-button
      class="left-block"
      secondary
      (click)="onExit()"
      data-cy="continue-shopping-button"
    >
      <span>Continue Shopping</span>
    </atom-button>
    <atom-button
      class="right-block"
      [disabled]="cartFormGroup.invalid"
      (click)="onProceed()"
    >
      <span>Proceed</span>
    </atom-button>
  </div>
</form>
