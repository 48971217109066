@if (gettingPaymentLinkIsLoading$ | async) {
  <ev-loading-overlay [title]="placingOrderText"></ev-loading-overlay>
}

<form [formGroup]="purchaseFormGroup">
  <div class="table-title mb-4 flex justify-between">
    <ng-container>Cart Review</ng-container>
  </div>

  <div class="addresses grid grid-cols-3 max-lg:grid-cols-1 gap-4 m-4">
    <div class="shipping-address">
      <div class="font-bold">Shipping Address</div>
      <div>{{ orderInfoFormGroup.value.shippingAddress?.street }}</div>
      <div>
        {{ orderInfoFormGroup.value.shippingAddress?.zipCode }},
        {{ orderInfoFormGroup.value.shippingAddress?.city }}
      </div>
      <div>{{ orderInfoFormGroup.value.shippingAddress?.additionalInfo }}</div>
    </div>
    <div class="billing-address">
      <div class="font-bold">Billing Address</div>
      <div>{{ orderInfoFormGroup.value.billingAddress?.street }}</div>
      <div>
        {{ orderInfoFormGroup.value.billingAddress?.zipCode }},
        {{ orderInfoFormGroup.value.billingAddress?.city }}
      </div>
      <div>{{ orderInfoFormGroup.value.billingAddress?.additionalInfo }}</div>
    </div>
    <div class="empty-row"></div>
  </div>

  <div
    class="additional-info-box grid grid-cols-3 max-lg:grid-cols-1 gap-4 mb-6 px-8 py-4 max-sm:p-2"
    formGroupName="cart"
  >
    <!-- User info -->
    <div class="name">
      <div class="ev-input-label font-bold">Name</div>
      <div class="read-only">{{ orderInfoFormGroup.value.userInfo?.name }}</div>
    </div>
    <div class="name">
      <div class="ev-input-label font-bold">Email</div>
      <div class="read-only">
        {{ orderInfoFormGroup.value.userInfo?.email }}
      </div>
    </div>
    <div class="name">
      <div class="ev-input-label font-bold">Phone</div>
      <div class="read-only">
        {{ orderInfoFormGroup.value.userInfo?.phoneNumber || "-" }}
      </div>
    </div>

    <!-- PST & PAN -->
    <div class="name">
      <div class="ev-input-label font-bold">GST Number</div>
      <div class="read-only">
        {{ orderInfoFormGroup.value.userInfo?.gstNumber }}
      </div>
    </div>
    <div class="name">
      <div class="ev-input-label font-bold">PAN Number</div>
      <div class="read-only">
        {{ orderInfoFormGroup.value.userInfo?.panNumber }}
      </div>
    </div>
    <div class="empty-col"></div>

    <!-- Purchase order & Delivery Instructions -->
    <div class="pon-container">
      <div class="ev-input-label font-bold">Purchase order number</div>
      <div class="read-only">{{ cartFormGroup.value.poNumber }}</div>
    </div>

    <div class="delivery-instructions-container">
      <div class="input-wrapper">
        <div class="ev-input-label font-bold">Delivery Instructions</div>
        {{ cartFormGroup.value.deliveryInstructions || "-" }}
      </div>
    </div>
    <div class="empty-col"></div>
  </div>

  <dp-line-item-table [cart]="cart" [readOnly]="true"></dp-line-item-table>

  <div
    class="actions-buttons mt-8 flex flex-wrap gap-4 justify-between max-sm:flex-col-reverse"
  >
    <atom-button class="left-block" secondary (click)="purchaseService.back()">
      <span>Back</span>
    </atom-button>
    <div class="right-block flex gap-6 max-sm:flex-col">
      <div class="terms flex items-center" formGroupName="review">
        <atom-checkbox
          class="inline-checkbox"
          atomsControl
          formControlName="agreedToTerms"
          [label]="agreeToTermLabel"
        ></atom-checkbox>
        <div class="link">
          &nbsp;
          <a class="cursor-pointer" (click)="onNavigateToTerms()">
            <span>Terms and Conditions</span>
          </a>
        </div>
      </div>
      <atom-button
        (click)="onProceedToPayment()"
        [disabled]="reviewFormGroup.invalid"
      >
        <span>Proceed to Payment</span>
      </atom-button>
    </div>
  </div>
</form>
