import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  inject,
  Input,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AngularModule } from '@atoms/angular';
// import { CurrentLocationsComponent } from '@ev-portals/dp/frontend/shared/feature';
import { CartFacade } from '@ev-portals/dp/frontend/purchase/data-access';
import { CartResponseDto, WarehouseLocation } from '@ev-portals/dp/frontend/shared/api-client';
import { FeedbackMessageService, NavigationService } from '@ev-portals/dp/frontend/shared/util';
import { LoadingOverlayComponent } from '@ev-portals/ev/frontend/ui-library';
import { DialogService } from '@ev-portals/ev/frontend/util';
import { first } from 'rxjs';

import { LineItemTableComponent } from '../line-item-table';
import { PurchaseService } from '../purchase.service';

@Component({
  selector: 'dp-cart',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    AngularModule,
    FormsModule,
    LoadingOverlayComponent,
    RouterModule,
    LineItemTableComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CartComponent {
  #purchaseService = inject(PurchaseService);
  #navigationService = inject(NavigationService);
  #dialogService = inject(DialogService);
  #feedbackMessageService = inject(FeedbackMessageService);
  #cartFacade = inject(CartFacade);

  @Input() cart: CartResponseDto;

  purchaseFormGroup = this.#purchaseService.purchaseFormGroup;
  cartFormGroup = this.#purchaseService.cartFormGroup;
  delInstructionPlaceholder = 'Fill delivery instruction';
  poNumPlaceholder = 'Enter purchase order number';
  selectedLocation = null;

  wareHouseOptions = Object.values(WarehouseLocation);

  constructor() {
    setTimeout(() => {
      this.#navigationService.scrollToTop();
    }, 0);
  }

  onExit(): void {
    this.#navigationService.navigateToProducts();
  }

  onProceed(): void {
    this.#purchaseService.proceed();
  }

  async onClearCart(): Promise<void> {
    const confirmResult = await this.#dialogService.confirm(
      'Are you sure you want to clear your cart?',
      'Clear Cart',
    );
    if (!confirmResult) {
      return;
    }

    this.#cartFacade
      .clearCart()
      .pipe(first())
      .subscribe({
        complete: () => {
          this.#feedbackMessageService.showSuccessMessage('Cart cleared successfully.');
        },
        error: () => {
          this.#feedbackMessageService.showErrorMessage();
        },
      });
  }
}
