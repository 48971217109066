import { CommonModule } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { CartFacade } from '@ev-portals/dp/frontend/purchase/data-access';
import { CartResponseDto } from '@ev-portals/dp/frontend/shared/api-client';
import { NotificationComponent, TableHeaderDirective } from '@ev-portals/ev/frontend/ui-library';
import { DialogService, Price, PricePipeModule } from '@ev-portals/ev/frontend/util';

import { LineItemComponent } from '../line-item/line-item.component';
import { PurchaseService } from '../purchase.service';

@Component({
  standalone: true,
  imports: [
    LineItemComponent,
    PricePipeModule,
    NotificationComponent,
    CommonModule,
    TableHeaderDirective,
  ],
  selector: 'dp-line-item-table',
  templateUrl: './line-item-table.component.html',
  styleUrls: ['./line-item-table.component.scss'],
})
export class LineItemTableComponent {
  #purchaseService = inject(PurchaseService);
  #dialogService = inject(DialogService);
  #cartFacade = inject(CartFacade);
  // TODO: add later
  // private analyticsService = inject(AnalyticsService);

  @Input() cart: CartResponseDto;
  @Input() readOnly = false;

  cartFormGroup = this.#purchaseService.cartFormGroup;
  public get totalGrossPrice(): Price {
    const lineItemControls = this.cartFormGroup.controls.lineItems.controls;
    const totalGrossPrice = lineItemControls.reduce((accumulator, lineItemControl, index) => {
      const lineItemTotalGrossPrice =
        this.cart.lineItems[index].article.grossPrice.value *
        (lineItemControl.controls.quantity.value ?? 0);

      return accumulator + lineItemTotalGrossPrice;
    }, 0);

    return {
      value: totalGrossPrice,
      currency: this.cart.lineItems[0].article.grossPrice.currency,
    };
  }

  async onRemoveItem(lineItemId: string, quantity: number): Promise<void> {
    const confirmResult = await this.#dialogService.confirm(
      'Are you sure you want to remove this item?',
      'Remove Item',
    );
    if (!confirmResult) {
      return;
    }

    // TODO: add later
    // this.analyticsService.trackEvent(
    //   'purchase',
    //   'removeArticleFromCart',
    //   'action',
    //   JSON.stringify({ articleId: lineItemId, quantity })
    // );
    this.#cartFacade.removeLineItem(lineItemId).subscribe();
  }
}
