import { Component, CUSTOM_ELEMENTS_SCHEMA, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'dp-empty-cart',
  template: `
    <div class="text-container flex flex-col items-center gap-4 my-28 max-sm:my-14">
      <div class="title">Your cart is empty</div>
      <div class="subtitle">Please add items to your cart to continue.</div>
    </div>
    <div class="button-container flex max-sm:flex-col">
      <atom-button secondary (click)="continueShopping.next()">
        <span>Continue Shopping</span>
      </atom-button>
    </div>
  `,
  styles: [
    `
      .title {
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
      }

      .subtitle {
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
      }
    `,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  standalone: true,
})
export class EmptyCartComponent {
  @Output() continueShopping = new EventEmitter<void>();
}
