import { inject, Injectable } from '@angular/core';
import { VerifyPaymentResponseDto } from '@ev-portals/dp/api/purchase/model';
import {
  ActionAddLineItem,
  ActionChangeLineItemQuantity,
  ActionChangeRequestedDeliveryDate,
  ActionPatchBillingAddress,
  ActionPatchShippingAddress,
  ActionPatchUserInfo,
  ActionRemoveLineItem,
  ActionSetDeliveryInstructions,
  ActionSetPurchaseOrderNumber,
  ActionSetWareHouseLocation,
  AddressDto,
  CartLineItemDto,
  CartResponseDto,
  OrderCartDto,
  OrderPlacedResponseDto,
  PatchAddressDto,
  PatchUserInfoDto,
  PaymentLinkResponseDto,
  PurchaseApiService,
  WarehouseLocation,
} from '@ev-portals/dp/frontend/shared/api-client';
import { AuthenticationService } from '@ev-portals/dp/frontend/shared/auth/data-access';
import { CartProxyService } from '@ev-portals/dp/frontend/shared/data-access';
import { NavigationService } from '@ev-portals/dp/frontend/shared/util';
import { catchError, Observable, ReplaySubject, Subject, switchMap, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CartFacade {
  #authService = inject(AuthenticationService);
  #purchaseApiService = inject(PurchaseApiService);
  #cartProxyService = inject(CartProxyService);
  #navigationService = inject(NavigationService);

  #cart: CartResponseDto;
  #cart$ = new ReplaySubject<CartResponseDto>(1);
  cart$ = this.#cart$.asObservable();

  #requestError$ = new Subject<string>();
  requestError$ = this.#requestError$.asObservable();

  constructor() {
    this.#initListeners();
  }

  #initListeners() {
    this.#authService.loginEvent$.subscribe(loginEvent => {
      console.log('[event] login');
      // Load cart data
      this.loadCartData().subscribe({
        ...this.#errorHandler('Error during loading the cart'),
      });

      this.#syncAddedCartItems();
    });
  }

  #syncAddedCartItems() {
    return this.#cartProxyService.addedCartItems$
      .pipe(switchMap(cartItem => this.addArticle(cartItem.articleId, cartItem.quantity)))
      .subscribe({
        ...this.#errorHandler('Error during adding line item to the cart'),
      });
  }

  loadCartData(): Observable<CartResponseDto> {
    return this.#purchaseApiService.getCurrentCart().pipe(
      tap(cart => {
        this.updateCartLocally(cart);
      }),
    );
  }

  #errorHandler(errorMessage: string) {
    return {
      error: (error: Error) => console.error(`[CartFacade] ${errorMessage}`, error),
    };
  }

  updateCartLocally(cart: CartResponseDto): void {
    this.#setTotalNumberOfCartItems(cart.lineItems);
    this.#cart = cart;
    this.#cart$.next(cart);
  }

  saveShippingAddress(shippingAddress: PatchAddressDto): Observable<CartResponseDto> {
    return this.#updateCartRemotely({
      action: 'patchShippingAddress',
      orderId: this.#cart.orderId,
      shippingAddress,
    }).pipe(tap(cart => this.updateCartLocally(cart)));
  }

  saveBillingAddress(billingAddress: PatchAddressDto): Observable<CartResponseDto> {
    return this.#updateCartRemotely({
      action: 'patchBillingAddress',
      orderId: this.#cart.orderId,
      billingAddress,
    }).pipe(tap(cart => this.updateCartLocally(cart)));
  }

  saveUserInfo(userInfo: PatchUserInfoDto): Observable<CartResponseDto> {
    return this.#updateCartRemotely({
      action: 'patchUserInfo',
      orderId: this.#cart.orderId,
      userInfo,
    }).pipe(tap(cart => this.updateCartLocally(cart)));
  }

  changeQuantity(lineItemId: string, quantity: number): void {
    if (typeof quantity !== 'number') {
      throw new Error('Quantity must be a number:` + ` ${quantity}');
    }

    const lineItems = this.#cart.lineItems.map(lineItem => {
      // We have to change the reference to rerender the component with onPush changeDetection
      if (lineItem.id === lineItemId) {
        const newLineItem = { ...lineItem };
        newLineItem.quantity = quantity;
        newLineItem.grossPrice.value = newLineItem.article.grossPricePerUnit.value * quantity;
        return newLineItem;
      } else {
        return lineItem;
      }
    });
    this.#patchCartLocally({
      lineItems,
    });

    this.#updateCartRemotely({
      action: 'changeLineItemQuantity',
      lineItemId,
      quantity,
    }).subscribe({
      next: cart => {
        this.updateCartLocally(cart);
      },
    });
  }

  changeDeliveryDate(lineItemId: string, requestedDeliveryDate: string): void {
    const lineItems = this.#cart.lineItems.map(lineItem => {
      if (lineItem.id === lineItemId) {
        const newLineItem = { ...lineItem };
        newLineItem.requestedDeliveryDate = requestedDeliveryDate;
        return newLineItem;
      } else {
        return lineItem;
      }
    });
    this.#patchCartLocally({
      lineItems,
    });

    this.#updateCartRemotely({
      action: 'changeRequestedDeliveryDate',
      lineItemId,
      requestedDeliveryDate,
    }).subscribe();
  }

  changeDeliveryInstructions(deliveryInstructions: string): void {
    this.#patchCartLocally({ deliveryInstructions });

    this.#updateCartRemotely({
      action: 'setDeliveryInstructions',
      orderId: this.#cart.orderId,
      deliveryInstructions,
    }).subscribe();
  }

  changePurchaseOrderNumber(poNumber: string): void {
    this.#patchCartLocally({
      poNumber,
    });

    this.#updateCartRemotely({
      action: 'setPurchaseOrderNumber',
      orderId: this.#cart.orderId,
      poNumber,
    }).subscribe();
  }

  changeWareHouseLocation(warehouseLocation: WarehouseLocation | null): void {
    this.#patchCartLocally({
      warehouseLocation: warehouseLocation,
    });

    this.#updateCartRemotely({
      action: 'setWareHouseLocation',
      orderId: this.#cart.orderId,
      warehouseLocation,
    }).subscribe();
  }

  public getPaymentLink(): Observable<PaymentLinkResponseDto | OrderPlacedResponseDto> {
    const orderCart = this.mapCartToOrderCartDto();

    return this.#purchaseApiService
      .validateCartAndGetPaymentLink({
        body: orderCart,
      })
      .pipe(
        tap(response => {
          // this is an edge case (if payment was successful, but something else happened during redirect from payment provider to our frontend and then the user tried to "proceed to payment" again)
          if ('orderPlaced' in response) {
            this.#navigationService.navigateToOrderConfirmation();
          }
        }),
      );
  }

  public verifyPayment(): Observable<VerifyPaymentResponseDto> {
    return this.#purchaseApiService.verifyPayment();
  }

  public placeOrderAndCleanUp() {
    return this.#purchaseApiService.placeOrderAndCleanUp();
  }

  private mapCartToOrderCartDto(): OrderCartDto {
    return {
      id: this.#cart.id,
      orderId: this.#cart.orderId,
      lineItems: this.#cart.lineItems.map(lineItem => ({
        id: lineItem.id,
        quantity: lineItem.quantity,
        requestedDeliveryDate: lineItem.requestedDeliveryDate as string,
        article: lineItem.article,
        grossPrice: lineItem.grossPrice,
        netPrice: lineItem.netPrice,
      })),
      shippingAddress: this.#cart.shippingAddress as AddressDto,
      billingAddress: this.#cart.billingAddress,
      userInfo: this.#cart.userInfo,
      deliveryInstructions: this.#cart.deliveryInstructions,
      poNumber: this.#cart.poNumber,
      totalGrossPrice: this.#cart.totalGrossPrice,
      totalNetPrice: this.#cart.totalNetPrice,
    };
  }

  public clearCart(): Observable<void> {
    // call the delete cart endpoint
    return this.#purchaseApiService.deleteCurrentCart().pipe(
      tap(() => {
        this.#patchCartLocally({
          poNumber: null,
          deliveryInstructions: null,
          warehouseLocation: null,
        });

        // Empty cart locally
        this.#patchCartLocally({ lineItems: [] });
      }),
    );
  }

  removeLineItem(lineItemId: string): Observable<CartResponseDto> {
    const updates = {
      lineItems: this.#cart.lineItems.filter(lineItem => lineItem.id !== lineItemId),
    };

    this.#patchCartLocally(updates);

    return this.#updateCartRemotely({
      action: 'removeLineItem',
      lineItemId,
    }).pipe(tap(cart => this.updateCartLocally(cart)));
  }

  addArticle(articleId: string, quantity: number): Observable<CartResponseDto> {
    return this.#updateCartRemotely({
      action: 'addLineItem',
      cartId: this.#cart.id,
      articleId: articleId,
      quantity,
    }).pipe(
      tap(cart => {
        this.#patchCartLocally(cart);
      }),
    );
  }

  #patchCartLocally(partialCart: Partial<CartResponseDto>): CartResponseDto {
    const updatedCart = { ...this.#cart, ...partialCart };
    this.updateCartLocally(updatedCart);

    return updatedCart;
  }

  #setTotalNumberOfCartItems(lineItems: CartLineItemDto[]): void {
    let itemTotalCount = 0;
    lineItems?.forEach(lineItem => {
      // If it's a bulk item, calculate the quantity as 1
      itemTotalCount += lineItem.quantity;
    });
    this.#cartProxyService.setNumberOfCartItems(itemTotalCount);
  }

  #updateCartRemotely(action: CartAction): Observable<CartResponseDto> {
    const payload = this.#convertActionToPayload(action);

    return this.#purchaseApiService.patchCurrentCart(payload).pipe(
      catchError(error => {
        const errorMessage = 'Error, during the execution of cart action';
        this.#cartProxyService.setRequestError(errorMessage);
        console.log(`[CartFacade] Error during cart update: ${errorMessage}`);
        this.#requestError$.next(errorMessage);

        // Restore the previous state
        return this.cart$;
      }),
    );
  }

  #convertActionToPayload(action: CartAction): {
    body: { action: CartAction };
  } {
    const payload = {
      body: { action },
    };

    return payload;
  }
}

export type CartAction =
  | ActionAddLineItem
  | ActionChangeLineItemQuantity
  | ActionChangeRequestedDeliveryDate
  | ActionRemoveLineItem
  | ActionSetPurchaseOrderNumber
  | ActionPatchBillingAddress
  | ActionPatchShippingAddress
  | ActionPatchUserInfo
  | ActionSetDeliveryInstructions
  | ActionSetWareHouseLocation;
