import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, CUSTOM_ELEMENTS_SCHEMA, inject } from '@angular/core';
// import { CurrentLocationsComponent } from '@ev-portals/dp/frontend/shared/feature';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AngularModule } from '@atoms/angular';
import { CartFacade } from '@ev-portals/dp/frontend/purchase/data-access';
import { CartResponseDto } from '@ev-portals/dp/frontend/shared/api-client';
import { NavigationService } from '@ev-portals/dp/frontend/shared/util';
import {
  ConfirmDialogComponent,
  LoadingOverlayComponent,
  NotificationComponent,
  PromptDialogComponent,
  TableHeaderDirective,
} from '@ev-portals/ev/frontend/ui-library';
import { DialogService, PricePipeModule } from '@ev-portals/ev/frontend/util';
import { BehaviorSubject, Observable } from 'rxjs';

import { CartComponent } from './cart';
import { EmptyCartComponent } from './empty-cart';
import { LineItemComponent } from './line-item';
import { OrderInfoComponent } from './order-info';
import { PurchaseService, Step } from './purchase.service';
import { ReviewComponent } from './review';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    AngularModule,
    LineItemComponent,
    OrderInfoComponent,
    // CurrentLocationsComponent,
    LoadingOverlayComponent,
    RouterModule,
    EmptyCartComponent,
    PricePipeModule,
    TableHeaderDirective,
    PromptDialogComponent,
    ConfirmDialogComponent,
    NotificationComponent,
    CartComponent,
    ReviewComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './purchase.component.html',
  styleUrls: ['./purchase.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PurchaseComponent {
  #purchaseService = inject(PurchaseService);
  #cartFacade = inject(CartFacade);
  #navigationService = inject(NavigationService);
  #dialogService = inject(DialogService);
  // TODO: add later
  // private analyticsService = inject(AnalyticsService);

  Step = Step;
  $currentStep = this.#purchaseService.$currentStep;
  cart$: Observable<CartResponseDto> = this.#cartFacade.cart$;
  purchaseFormGroup = this.#purchaseService.purchaseFormGroup;
  cartFormGroup = this.#purchaseService.cartFormGroup;
  orderInfoFormGroup = this.#purchaseService.orderInfoFormGroup;
  reviewFormGroup = this.#purchaseService.reviewFormGroup;

  placingOrderIsLoading$ = new BehaviorSubject(false);
  delInstructionPlaceholder = 'Fill delivery instruction';
  poNumPlaceholder = 'Enter purchase order number';
  agreeToTermLabel = 'I have read and agree with the';
  calculatingText = 'Calculating';
  placingOrderText = 'Placing order';

  constructor() {
    this.#initRequestErrorListener();
  }

  #initRequestErrorListener(): void {
    this.#cartFacade.requestError$.pipe(takeUntilDestroyed()).subscribe(errorMessage => {
      this.#dialogService
        .alert('Sorry, we had a critical error, we have to refresh the page.' + `\n${errorMessage}`)
        .then(() => location.reload());
    });
  }

  onStepChange(customEvent: Event): void {
    // We handle just step backs to the 1st step (cart)
    const clickedStep = (customEvent as CustomEvent).detail.current;
    if (clickedStep < this.#purchaseService.$currentStep()) {
      this.#purchaseService.$currentStep.set(clickedStep);
    }
  }

  onExit(): void {
    this.#navigationService.navigateToProducts();
  }
}
