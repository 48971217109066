import { CommonModule, formatNumber } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  EventEmitter,
  Inject,
  Input,
  LOCALE_ID,
  OnInit,
  Output,
  signal,
} from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AngularModule } from '@atoms/angular';
import { CartLineItemDto } from '@ev-portals/dp/frontend/shared/api-client';
import {
  NotificationComponent,
  QuantitySelectorComponent,
} from '@ev-portals/ev/frontend/ui-library';
import { PricePipeModule } from '@ev-portals/ev/frontend/util';
import { format, startOfTomorrow } from 'date-fns';
import { startWith } from 'rxjs';

import { CartItemForm } from '../purchase.service';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    AngularModule,
    QuantitySelectorComponent,
    PricePipeModule,
    NotificationComponent,
    RouterModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  selector: 'dp-line-item',
  templateUrl: './line-item.component.html',
  styleUrls: ['./line-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LineItemComponent implements OnInit {
  @Input() readOnly = true;
  @Input() cartItem: CartLineItemDto;
  @Input() formGroup: FormGroup<CartItemForm>;

  @Output() removeItem = new EventEmitter<void>();

  tomorrowDate = format(startOfTomorrow(), 'dd/MM/yyyy');
  totalAmount = signal('-');

  constructor(@Inject(LOCALE_ID) public locale: string) {}

  ngOnInit(): void {
    this.formGroup.controls.quantity.valueChanges
      .pipe(startWith(this.formGroup.value.quantity))
      .subscribe(enteredQuantity =>
        this.totalAmount.set(this.#getTotalAmount(enteredQuantity ? +enteredQuantity : null)),
      );
  }

  #getTotalAmount(enteredQuantity: number | null): string {
    if (!enteredQuantity || !Number.isInteger(enteredQuantity)) {
      return '-';
    }

    const totalAmount = enteredQuantity * this.cartItem.article.packagingSize.value;

    // Let's format the totalAmount
    return formatNumber(totalAmount, this.locale, '1.2-2');
  }

  onRemoveItem(): void {
    this.removeItem.emit();
  }
}
