import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  inject,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { CartFacade } from '@ev-portals/dp/frontend/purchase/data-access';
import { FeedbackMessageService, NavigationService } from '@ev-portals/dp/frontend/shared/util';
import { LoadingOverlayComponent } from '@ev-portals/ev/frontend/ui-library';
import { InvalidCheckDirective } from '@ev-portals/ev/frontend/util';
import { isNull, omitBy } from 'lodash';
import { debounceTime, tap } from 'rxjs';

import { PurchaseService } from '../purchase.service';

@Component({
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, InvalidCheckDirective, LoadingOverlayComponent],
  selector: 'dp-order-info',
  templateUrl: './order-info.component.html',
  styleUrls: ['./order-info.component.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class OrderInfoComponent implements AfterViewInit {
  purchaseService = inject(PurchaseService);
  #cartFacade = inject(CartFacade);
  #feedbackMessageService = inject(FeedbackMessageService);
  #navigationService = inject(NavigationService);
  #changeRef = inject(ChangeDetectorRef);

  $loading = signal(false);
  orderInfoFormGroup = this.purchaseService.orderInfoFormGroup;
  controls = {
    userInfo: this.orderInfoFormGroup.controls.userInfo.controls,
    shippingAddress: this.orderInfoFormGroup.controls.shippingAddress.controls,
    billingAddress: this.orderInfoFormGroup.controls.billingAddress.controls,
  };
  sameBillingAddress = false;
  sameBillingAddressLabel = 'Billing address is same as shipping address';
  #DEBOUNCE_TIME = 500; // in ms

  placeHolderTexts = {
    userInfo: {
      name: 'Name',
      email: 'Email',
      phoneNumber: 'Phone',
      gstNumber: 'GST Number',
      panNumber: 'PAN Number',
    },
    shippingAddress: {
      zipCode: 'Zip Code',
      city: 'City',
      street: 'Street',
      additionalInfo: 'Additional Information',
    },
    billingAddress: {
      zipCode: 'Zip Code',
      city: 'City',
      street: 'Street',
      additionalInfo: 'Additional Information',
    },
  };

  constructor() {
    this.#listenToChanges();
  }

  ngAfterViewInit(): void {
    this.#navigationService.scrollToTop();
  }

  #listenToChanges(): void {
    this.#listenToShippingAddressChanges();
    this.#listenToBillingAddressChanges();
    this.#listenToUserInfoChanges();
  }

  #listenToUserInfoChanges(): void {
    this.orderInfoFormGroup.controls.userInfo.valueChanges
      .pipe(
        debounceTime(this.#DEBOUNCE_TIME),
        tap(value => {
          if (value.phoneNumber) {
            const phoneNumberControl =
              this.orderInfoFormGroup.controls.userInfo.controls.phoneNumber;

            if (phoneNumberControl.invalid) {
              phoneNumberControl.markAsTouched();
            }

            value.phoneNumber = value.phoneNumber.trim();
          }
        }),
        takeUntilDestroyed(),
      )
      .subscribe(value => {
        this.#cartFacade.saveUserInfo(omitBy(value, isNull)).subscribe();
      });
  }
  #listenToShippingAddressChanges(): void {
    this.orderInfoFormGroup.controls.shippingAddress.valueChanges
      .pipe(debounceTime(this.#DEBOUNCE_TIME))
      .subscribe(value => {
        if (this.sameBillingAddress) {
          this.#copyShippingAddressToBillingAddress();
        }
        // Save if changed and valid
        this.#cartFacade.saveShippingAddress(omitBy(value, isNull)).subscribe();
      });
  }
  #listenToBillingAddressChanges(): void {
    this.orderInfoFormGroup.controls.billingAddress.valueChanges
      .pipe(debounceTime(this.#DEBOUNCE_TIME))
      .subscribe(value => {
        // Save if changed and valid
        this.#cartFacade.saveBillingAddress(omitBy(value, isNull)).subscribe();
      });
  }

  public invalidCheck(control: FormControl): boolean {
    return control.invalid && control.touched;
  }

  onSubmit(): void {
    this.orderInfoFormGroup.markAllAsTouched();
    this.#changeRef.detectChanges();
    if (!this.orderInfoFormGroup.valid) {
      return this.#feedbackMessageService.showErrorMessage('Please fill all required fields.');
    } else {
      this.orderInfoFormGroup.markAsUntouched();
      this.purchaseService.proceed();
    }
  }

  onSameBillingAddressChange(event: Event) {
    this.sameBillingAddress = (event.target as HTMLInputElement).checked;
    if (this.sameBillingAddress) {
      this.#copyShippingAddressToBillingAddress();
    } else {
      this.#emptyBillingAddress();
    }
  }

  #emptyBillingAddress() {
    this.orderInfoFormGroup.controls.billingAddress.patchValue(
      {
        zipCode: '',
        city: '',
        street: '',
        additionalInfo: '',
      },
      { emitEvent: false },
    );
  }

  #copyShippingAddressToBillingAddress() {
    this.orderInfoFormGroup.controls.billingAddress.patchValue({
      zipCode: this.controls.shippingAddress.zipCode.value,
      city: this.controls.shippingAddress.city.value,
      street: this.controls.shippingAddress.street.value,
      additionalInfo: this.controls.shippingAddress.additionalInfo.value,
    });
  }
}

type UserInfoValues = {
  name: string | null;
  email: string | null;
  phoneNumber: string | null;
  gstNumber: string | null;
  panNumber: string | null;
};

type UserInfoValueChangePayload = Partial<UserInfoValues>;
